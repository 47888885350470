<template>
    <div :class="$style.time">
        <pt-button
            color="grey"
            :icon="{
                name: 'PTX-icon-calendar-20',
                width: '18px',
                height: '18px'
            }"
            @click="handleValueClick"
        >
            {{ showName }}
        </pt-button>
        <div :class="$style.dropdown" v-if="visibleDropdown" v-pt-clickoutside="handleClickOutside">
            <div :class="$style.dropdownWrap">
                <pt-scrollbar ref="scrollbar">
                    <ul>
                        <li
                            v-for="item in timeItems"
                            :key="item.code"
                            :class="{ separator: item.code === 'separator', category: item.code === 'category' }"
                        >
                            <span v-if="item.code === 'category'">{{ $t(item.i18nCode) }}</span>
                            <template v-if="['separator', 'category'].indexOf(item.code) === -1">
                                <pt-radio
                                    theme="light"
                                    name="time"
                                    :value="currentItem.code"
                                    :val="item.code"
                                    @change="handleItemChange"
                                >
                                    {{ $t(item.i18nCode) }}
                                </pt-radio>

                                <!--过去多少天-->
                                <div
                                    v-show="currentItem.code == 'past'"
                                    v-if="item.code == 'past'"
                                    :class="$style.dateHideBox"
                                >
                                    <div class="number-input">
                                        <pt-input-number
                                            theme="light"
                                            :min="states.past.min"
                                            :max="states.past.max"
                                            :value="states.past.value"
                                            @change="val => handleStatesChange('past', 'value', val)"
                                            @enter="handleConfirmClick"
                                        ></pt-input-number>
                                    </div>
                                    <div class="checkbox">
                                        <el-checkbox
                                            :value="states.past.isIncludeToday"
                                            @change="val => handleStatesChange('past', 'isIncludeToday', val)"
                                        >
                                            {{ $t('time_range.include_today') }}
                                        </el-checkbox>
                                    </div>
                                </div>

                                <!--时间范围-->
                                <div
                                    v-show="currentItem.code == 'custom'"
                                    v-if="item.code == 'custom' && item.type === 'range'"
                                    :class="$style.dateHideBox"
                                >
                                    <span class="line"></span>
                                    <el-date-picker
                                        type="date"
                                        placeholder="选择日期"
                                        :value-format="dateFormat"
                                        :class="$style.datePicker"
                                        :picker-options="pickerOptions.startTime"
                                        :clearable="false"
                                        :editable="false"
                                        v-model="states.custom.startTime"
                                        @change="toggleClickoutStatus(false)"
                                        @focus="toggleClickoutStatus(false)"
                                        @blur="toggleClickoutStatus(true)"
                                    ></el-date-picker>

                                    <el-date-picker
                                        type="date"
                                        placeholder="选择日期"
                                        :value-format="dateFormat"
                                        :class="$style.datePicker"
                                        :picker-options="pickerOptions.endTime"
                                        :clearable="false"
                                        :editable="false"
                                        v-model="states.custom.endTime"
                                        @change="toggleClickoutStatus(false)"
                                        @focus="toggleEndTimeStatus(false)"
                                        @blur="toggleClickoutStatus(true)"
                                    ></el-date-picker>
                                </div>
                                <div
                                    v-show="currentItem.code == 'userUpdate'"
                                    v-if="item.code == 'userUpdate'"
                                    :class="$style.dateHideBox"
                                >
                                    {{
                                        currentItem.startTime +
                                            '-' +
                                            (isToday(currentItem.endTime)
                                                ? $t('time_range.today')
                                                : currentItem.endTime)
                                    }}
                                </div>
                            </template>
                        </li>
                    </ul>
                </pt-scrollbar>
            </div>
            <div :class="$style.dropdownFooter">
                <pt-button color="grey" class="pt-mr10" @click="handleCancelClick">{{
                    $t('common.button_cancel')
                }}</pt-button>
                <pt-button color="blue" @click="handleConfirmClick">{{ $t('common.button_apply') }}</pt-button>
            </div>
        </div>
    </div>
</template>

<script>
import merge from 'lodash/merge';
import dayjs from 'dayjs';
import timeSelectorConfig from './time-selector.config';
import dateUtils from '@/common/utils/date.utils';

export default {
    name: 'TimeSelector',

    props: {
        timeZone: String,
        code: {
            type: String,
            default: 'past',
            validator: function(val) {
                return [
                    'today',
                    'yesterday',
                    'this_week',
                    'last_week',
                    'this_month',
                    'last_month',
                    'past',
                    'custom',
                    'userUpdate'
                ].includes(val);
            }
        },
        custom: Object,
        config: {
            type: Object,
            default: function() {
                return {
                    past: {
                        isIncludeToday: true,
                        value: 7
                    }
                };
            },
            interface: {
                past: {
                    isIncludeToday: true,
                    value: 7
                },
                custom: {
                    startTime: null,
                    endTime: null
                }
            }
        }
    },

    data() {
        const defaultItem = timeSelectorConfig.find(item => item.default);
        const states = merge(
            {
                past: {
                    min: 1,
                    max: 999,
                    isIncludeToday: true,
                    value: 7
                },
                custom: {
                    startTime: dateUtils.getMomentTime({
                        num: -7,
                        type: 'day',
                        isStart: true,
                        timezone: this.timeZone
                    }),
                    endTime: dateUtils.getMomentTime({
                        num: -1,
                        type: 'day',
                        isStart: true,
                        timezone: this.timeZone
                    })
                }
            },
            this.config
        );
        const timeItems = this.custom ? [this.custom].concat(timeSelectorConfig) : timeSelectorConfig;
        const item = this.code && timeItems.find(item => item.code === this.code);
        return {
            states,
            timeItems,
            currentItem: item || defaultItem,
            showName: null,
            clickOut: true,
            visibleDropdown: false,
            dateFormat: 'yyyy/MM/dd',
            pickerOptions: null
        };
    },

    created() {
        this.updatePickerOptions();
    },

    mounted() {
        this.notice();
        this.updateShowName();
    },

    methods: {
        handleItemChange(code) {
            this.updateCurrentItem(code);
        },

        handleStatesChange(type, key, value) {
            if (Object.prototype.hasOwnProperty.call(this.states[type], key)) {
                this.$set(this.states[type], key, value);
            }
        },

        handleClickOutside(e) {
            setTimeout(() => {
                this.clickOut ? this.hideItem() : (this.clickOut = true);
            });
        },
        toggleEndTimeStatus(status) {
            this.toggleClickoutStatus(status);
            this.updatePickerOptions();
        },
        toggleClickoutStatus(status) {
            this.clickOut = status;
        },

        updatePickerOptions() {
            const { startTime, endTime } = this.states.custom;
            const timeZone = this.timeZone;
            const nowDate = dateUtils.getDateForTimezone(timeZone);

            this.pickerOptions = {
                startTime: {
                    disabledDate(time) {
                        return dayjs(time).isAfter(nowDate) || (endTime && dayjs(time).isAfter(endTime));
                    },
                    firstDayOfWeek: 1
                },
                endTime: {
                    disabledDate(time) {
                        return dayjs(time).isAfter(nowDate) || (startTime && dayjs(time).isBefore(startTime));
                    },
                    firstDayOfWeek: 1
                }
            };
        },

        updateCurrentItem(code) {
            const item = this.timeItems.find(item => item.code === code);
            item && (this.currentItem = item);
        },

        updateShowName() {
            const { code, i18nCode, startTime, endTime } = this.currentItem || {};
            let name = '';
            switch (code) {
                case 'userUpdate':
                    name = `${this.$t(i18nCode)}: ${this.custom.startTime} - ${
                        this.custom.endTime === dayjs().format('YYYY/MM/DD')
                            ? this.$t('time_range.today')
                            : this.custom.endTime
                    }`;
                    break;
                case 'past':
                    name = this.$t('time_range.last', { day: this.states.past.value });
                    break;
                case 'custom':
                    const { startTime, endTime } = this.states.custom;
                    name = `${startTime} - ${endTime}`;
                    break;

                default:
                    name = this.$t(i18nCode);
                    break;
            }
            this.showName = name;
        },
        isToday(time) {
            return dayjs().format('YYYY/MM/DD') === time;
        },
        getTime() {
            const { code, startTime: customStartTime, endTime: customEndTime } = this.currentItem;
            const { past, custom } = this.states;
            let startTime = null;
            let endTime = null;
            switch (code) {
                case 'today':
                    startTime = endTime = dateUtils.getMomentTime({
                        num: 0,
                        type: 'day',
                        isStart: true,
                        timezone: this.timeZone
                    });
                    break;
                case 'yesterday':
                    startTime = endTime = dateUtils.getMomentTime({
                        num: -1,
                        type: 'day',
                        isStart: true,
                        timezone: this.timeZone
                    });
                    break;
                case 'this_week':
                    startTime = dateUtils.getMomentTime({
                        num: 0,
                        type: 'week',
                        isStart: true,
                        timezone: this.timeZone
                    });
                    endTime = dateUtils.getMomentTime({
                        num: 0,
                        type: 'day',
                        isStart: false,
                        timezone: this.timeZone
                    });
                    break;
                case 'last_week':
                    startTime = dateUtils.getMomentTime({
                        num: -1,
                        type: 'week',
                        isStart: true,
                        timezone: this.timeZone
                    });

                    endTime = dateUtils.getMomentTime({
                        num: -1,
                        type: 'week',
                        isStart: false,
                        timezone: this.timeZone
                    });
                    break;
                case 'this_month':
                    startTime = dateUtils.getMomentTime({
                        num: 0,
                        type: 'month',
                        isStart: true,
                        timezone: this.timeZone
                    });
                    endTime = dateUtils.getMomentTime({
                        num: 0,
                        type: 'day',
                        isStart: false,
                        timezone: this.timeZone
                    });
                    break;
                case 'last_month':
                    startTime = dateUtils.getMomentTime({
                        num: -1,
                        type: 'month',
                        isStart: true,
                        timezone: this.timeZone
                    });
                    endTime = dateUtils.getMomentTime({
                        num: -1,
                        type: 'month',
                        isStart: false,
                        timezone: this.timeZone
                    });
                    break;
                case 'past':
                    const { value, isIncludeToday } = past;
                    const startDays = -(value - (isIncludeToday ? 1 : 0));
                    const endDays = isIncludeToday ? 0 : -1;
                    startTime = dateUtils.getMomentTime({
                        num: startDays,
                        type: 'day',
                        isStart: true,
                        timezone: this.timeZone
                    });
                    endTime = dateUtils.getMomentTime({
                        num: endDays,
                        type: 'day',
                        isStart: false,
                        timezone: this.timeZone
                    });
                    break;
                case 'custom':
                    startTime = custom.startTime;
                    endTime = custom.endTime;
                    break;
                case 'userUpdate':
                    startTime = customStartTime;
                    endTime = customEndTime;
                    break;
            }
            return {
                startTime,
                endTime
            };
        },

        notice(isTrack) {
            const { startTime, endTime } = this.getTime();
            const timeSpan = Math.floor((dayjs(new Date(endTime)) - dayjs(new Date(startTime))) / 1000 / 86400) + 1;
            const timeDiff = this.getTimeDiff(startTime, endTime, timeSpan);

            this.$emit(
                'change',
                Object.assign({}, this.currentItem, {
                    startTime,
                    endTime,
                    timeSpan,
                    timeDiff,
                    configs: this.states
                }),
                isTrack
            );
        },
        getTimeDiff(startTime, endTime, timeSpan) {
            if (timeSpan === 1) {
                return dayjs(startTime, 'YYYY/MM/DD')
                    .subtract(1, 'days')
                    .format('MM/DD');
            } else {
                let lastStart = dayjs(startTime, 'YYYY/MM/DD')
                    .subtract(timeSpan, 'days')
                    .format('MM/DD');
                let lastEnd = dayjs(endTime, 'YYYY/MM/DD')
                    .subtract(timeSpan, 'days')
                    .format('MM/DD');
                return lastStart + '-' + lastEnd;
            }
        },
        //隐藏设置窗口
        hideItem() {
            this.visibleDropdown = false;
        },

        handleValueClick() {
            this.visibleDropdown = !this.visibleDropdown;
        },

        handleCancelClick() {
            this.hideItem();
        },

        handleConfirmClick() {
            this.hideItem();
            this.notice(true);
            this.updateShowName();
        }
    },

    watch: {
        code(newVal) {
            this.updateCurrentItem(newVal);
            this.updateShowName();
        },
        config: {
            handler: function(newVal) {
                this.states = merge(this.states, newVal || {});
                this.updateCurrentItem(newVal);
                this.updateShowName();
            },
            deep: true
        }
    }
};
</script>

<style lang="scss" module>
@import '@/styles/import.scss';

.time {
    position: relative;

    .dropdown {
        position: absolute;
        top: 100%;
        right: 0;
        margin: 0;
        z-index: 11;
        width: 220px;
        border-radius: 4px;
        padding: 16px 0;
        background-color: $pt-white;
        border: 1px solid $pt-black-50;
        box-shadow: 0 2px 6px 0 $pt-black-40;
        margin: 10px 0 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .dropdownWrap {
        overflow: hidden;
        flex: 1;

        ul {
            width: 100%;
            border-radius: 4px;
            padding: 16px 20px;
            background-color: $pt-white;
            margin-bottom: 0;
            position: relative;

            li {
                min-height: 26px;
                margin-bottom: 2px;

                &.separator {
                    width: 200px;
                    height: 1px;
                    background-color: $pt-black-50;
                    overflow: hidden;
                    min-height: auto;
                    margin: 6px 0 6px -10px;
                }

                &.category {
                    display: flex;
                    align-items: center;
                    color: $pt-black-600;
                    font-weight: bold;
                }
            }

            .dateHideBox {
                display: flex;
                flex-wrap: wrap;
                //padding-top: 10px;
                padding-bottom: 3px;
                margin-left: 20px;
                position: relative;

                .number-input {
                    width: 65px;
                    margin-bottom: 5px;
                }

                .pt-ui-button {
                    font-family: arial;
                    margin-bottom: 10px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    &:last-child {
                        margin: 0;
                    }
                    svg {
                        fill-opacity: 0.6;
                    }
                }

                .line {
                    width: 10px;
                    height: 37px;
                    border: 1px solid $pt-black-50;
                    border-right: none;
                    border-radius: 4px 0 0 4px;
                    position: absolute;
                    top: 22px;
                    left: -10px;
                }
            }

            .datepicker-box {
                position: absolute;
                bottom: 0;
                left: -15px;
            }

            .datePicker {
                width: 100% !important;
                margin-bottom: 8px;
            }
        }
    }

    .dropdownFooter {
        margin: 0 20px;
        display: flex;
        justify-content: flex-start;
    }

    // ===== transition =====
    .time-list--transition-vertical-enter-active,
    .time-list--transition-vertical-leave-active {
        transition: opacity ease 0.2s, transform ease 0.2s;
    }

    .time-list--transition-vertical-enter,
    .time-list--transition-vertical-leave-active {
        opacity: 0;
        transform: scale(0);
    }
}
</style>
