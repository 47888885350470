/**
 * {
 *  span: Number,
 *  code: String, //图表类别
 *  funName: String, //接口调用
 *  name: String, //图表显示名称
 *  sequentialFlag: true, //是否支持同环比
 *  chartSetting: {
 *      type: String, //图表类型
 *      data: Array, //图表数据
 *      dataType: {
 *          value: Number,
 *          unit: String,
 *          options: Array
 *      }, //图表展示数据类别：keyCount/userCount
 *      dataTypeOptions: Object, //图表展示数据选项
 *      dataFilter: Array, //图表数据类别选项
 *      value: Number, //图表总值数据
 *      height: 214, //图表高度，默认214
 *      visibleValue: Boolean, //是否显示总值
 *      visibleTypes: Boolean, //是否显示切换图表
 *      visibleFilter: Boolean, //是否显示图表过滤器
 *      filter: Array, //图表过滤器选项
 *  }
 * }
 */
import i18n from '@assets/i18n';

export default [
    {
        span: 12,
        code: 'userCurve',
        funName: 'userCurve',
        name: i18n.t('users.chart_user_active_trends'),
        tips: i18n.t('annotation.user_group_9'),
        sequentialFlag: true,
        chartSetting: {
            type: 'line',
            data: null,
            dataType: {
                value: 'userCount',
                unit: i18n.t('users.chart_unit_users')
            },
            value: null,
            height: 220,
            visibleValue: true,
            visibleTypes: false,
            visibleFilter: false,
            filter: [
                {
                    value: 'day',
                    postName: 'day',
                    options: [
                        {
                            code: 'day',
                            name: i18n.t('users.chart_actives_day')
                        },
                        {
                            code: 'week',
                            name: i18n.t('users.chart_actives_week')
                        }
                    ]
                }
            ]
        }
    },
    {
        span: 12,
        code: 'newUserCurve',
        funName: 'newUserCurve',
        name: i18n.t('users.chart_new_users'),
        tips: i18n.t('annotation.user_group_10'),
        sequentialFlag: true,
        chartSetting: {
            type: 'line',
            data: null,
            dataType: {
                value: 'userCount',
                unit: i18n.t('users.chart_unit_users')
            },
            value: null,
            height: 220,
            visibleValue: true,
            visibleTypes: false,
            visibleFilter: false,
            filter: [
                {
                    value: 'day',
                    postName: 'day',
                    options: [
                        {
                            code: 'day',
                            name: i18n.t('users.chart_actives_day')
                        },
                        {
                            code: 'week',
                            name: i18n.t('users.chart_actives_week')
                        }
                    ]
                }
            ]
        }
    },
    // {
    //     span: 8,
    //     code: 'topConversions',
    //     name: i18n.t('users.chart_top_conversions'),
    //     chartSetting: {
    //         type: 'table',
    //         data: null,
    //         dataType: {
    //              value: 'userCount',
    //          },
    //         visibleValue: true,
    //         visibleTypes: false,
    //         visibleFilter: false,
    //         filter: null
    //     }
    // },
    // {
    //     span: 16,
    //     code: 'conversionsTrends',
    //     name: i18n.t('users.chart_conversions_trends'),
    //     chartSetting: {
    //         type: 'column',
    //         data: null,
    //         value: null,
    //         visibleValue: true,
    //         visibleTypes: false,
    //         visibleFilter: false,
    //         filter: null
    //     }
    // },
    {
        span: 12,
        code: 'eventReport',
        funName: 'eventReport',
        name: i18n.t('users.chart_top_events'),
        tips: i18n.t('annotation.user_group_11'),
        chartSetting: {
            type: 'table',
            data: null,
            value: null,
            visibleValue: false,
            visibleTypes: false,
            visibleFilter: false,
            filter: null,
            tableColumn: [
                {
                    label: i18n.t('users.chart_top_event_table_name'),
                    width: '60%',
                    prop: 'key',
                    dataType: 'string'
                },
                {
                    label: i18n.t('users.chart_unit_users'),
                    width: '20%',
                    prop: 'userCount',
                    dataType: 'number'
                },
                {
                    label: i18n.t('users.chart_unit_events'),
                    width: '20%',
                    prop: 'keyCount',
                    dataType: 'number'
                }
            ]
        }
    },
    {
        span: 12,
        code: 'eventCurve',
        funName: 'eventCurve',
        name: i18n.t('users.chart_events_trends'),
        tips: i18n.t('annotation.user_group_12'),
        chartSetting: {
            type: 'line',
            data: null,
            value: null,
            height: 184,
            visibleValue: true,
            visibleTypes: false,
            visibleFilter: true,
            filter: [
                {
                    value: '',
                    postName: 'events',
                    options: [
                        {
                            code: '',
                            name: i18n.t('users.chart_all_events')
                        }
                    ]
                }
            ],
            dataType: {
                value: 'userCount',
                unit: i18n.t('users.chart_unit_users'),
                options: [
                    {
                        code: 'userCount',
                        unit: i18n.t('users.chart_unit_users'),
                        name: i18n.t('users.chart_by_users')
                    },
                    {
                        code: 'keyCount',
                        unit: i18n.t('users.chart_unit_events'),
                        name: i18n.t('users.chart_by_events')
                    }
                ]
            }
        }
    },
    {
        span: 8,
        code: 'impression',
        name: i18n.t('users.chart_engagement_viewed'),
        tips: i18n.t('annotation.user_group_13'),
        funName: 'engageReport',
        chartSetting: {
            type: 'number',
            data: null,
            dataType: {
                value: 'userCount',
                unit: i18n.t('users.chart_unit_users')
            },
            value: null,
            visibleValue: false,
            visibleTypes: false,
            visibleFilter: false,
            filter: []
        }
    },
    {
        span: 8,
        code: 'click',
        name: i18n.t('users.chart_engagement_clicked'),
        tips: i18n.t('annotation.user_group_14'),
        funName: 'engageReport',
        chartSetting: {
            type: 'number',
            data: null,
            dataType: {
                value: 'userCount',
                unit: i18n.t('users.chart_unit_users')
            },
            value: null,
            visibleValue: false,
            visibleTypes: false,
            visibleFilter: false,
            filter: []
        }
    },
    {
        span: 8,
        code: 'submit',
        name: i18n.t('users.chart_engagement_form'),
        tips: i18n.t('annotation.user_group_15'),
        funName: 'engageReport',
        chartSetting: {
            type: 'number',
            data: null,
            dataType: {
                value: 'userCount',
                unit: i18n.t('users.chart_unit_users')
            },
            value: null,
            visibleValue: false,
            visibleTypes: false,
            visibleFilter: false,
            filter: []
        }
    },
    // {
    //     span: 6,
    //     code: 'goal',
    //     name: i18n.t('users.chart_goal'),
    //     funName: 'engageReport',
    //     chartSetting: {
    //         type: 'number',
    //         data: null,
    //         dataType: {
    //              value: 'userCount',
    //          },
    //         value: null,
    //         visibleValue: true,
    //         visibleTypes: false,
    //         visibleFilter: false,
    //         filter: []
    //     }
    // },
    {
        span: 12,
        code: 'contentsReport',
        name: i18n.t('users.chart_top_contents'),
        tips: i18n.t('annotation.user_group_16'),
        funName: 'contentsReport',
        chartSetting: {
            type: 'table',
            data: null,
            value: null,
            visibleValue: false,
            visibleTypes: false,
            visibleFilter: false,
            filter: null,
            tableColumn: [
                {
                    label: i18n.t('users.chart_top_contents_pages'),
                    width: '60%',
                    prop: 'key',
                    dataType: 'string'
                },
                {
                    label: i18n.t('users.chart_top_contents_users'),
                    width: '20%',
                    prop: 'userCount',
                    dataType: 'number'
                },
                {
                    label: i18n.t('users.chart_top_contents_pageviews'),
                    width: '20%',
                    prop: 'keyCount',
                    dataType: 'number'
                }
            ]
        }
    },
    {
        span: 12,
        code: 'sourcesReport',
        name: i18n.t('users.chart_top_refferal'),
        tips: i18n.t('annotation.user_group_17'),
        funName: 'sourcesReport',
        chartSetting: {
            type: 'table',
            data: null,
            value: null,
            visibleValue: false,
            visibleTypes: false,
            visibleFilter: false,
            filter: null,
            tableColumn: [
                {
                    label: i18n.t('users.chart_top_refferal_sources'),
                    width: '60%',
                    prop: 'key',
                    dataType: 'string'
                },
                {
                    label: i18n.t('users.chart_top_refferal_users'),
                    width: '20%',
                    prop: 'userCount',
                    dataType: 'number'
                },
                {
                    label: i18n.t('users.chart_top_refferal_sessions'),
                    width: '20%',
                    prop: 'keyCount',
                    dataType: 'number'
                }
            ]
        }
    },
    {
        span: 8,
        code: 'deviceReport',
        name: i18n.t('users.chart_device_sources'),
        tips: i18n.t('annotation.user_group_18'),
        funName: 'deviceReport',
        chartSetting: {
            type: 'pie',
            data: null,
            dataType: {
                value: 'userCount',
                unit: i18n.t('users.chart_unit_users')
            },
            value: null,
            visibleValue: false,
            visibleTypes: false,
            visibleFilter: false,
            filter: null
        }
    },
    {
        span: 16,
        code: 'countryReport', //[countryReport / regionReport / cityReport]
        name: i18n.t('users.chart_city_breakdown'),
        tips: i18n.t('annotation.user_group_19'),
        funName: 'countryReport', //[countryReport / regionReport / cityReport]
        chartSetting: {
            type: 'map',
            data: null,
            dataType: {
                value: 'userCount'
            },
            value: null,
            visibleValue: false,
            visibleTypes: false,
            visibleFilter: false,
            filter: null,
            mapCode: 'world'
        }
    }
];
