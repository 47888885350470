<template>
    <div
        :class="$style.chart"
        v-loading="loading"
        element-loading-background="transparent"
        :element-loading-text="loadingText"
    >
        <template v-if="!loading">
            <div :class="$style.header">
                <div :class="$style.title">
                    <span>{{ chart.name }}</span>
                    <el-tooltip :content="chart.tips">
                        <pt-icon
                            icon="pt-icon--help-new"
                            :icon-style="{
                                width: '16px',
                                height: '16px',
                                fill: '#5E6C84',
                                margin: '0 0 10px 6px'
                            }"
                        ></pt-icon>
                    </el-tooltip>
                </div>

                <el-dropdown v-if="chart.chartSetting.visibleTypes" trigger="click" @command="handleChartTypeChange">
                    <div class="el-dropdown-link" :class="$style.dropdownLink">
                        <pt-icon
                            :icon="currentChart.icon"
                            :icon-style="{
                                width: '16px',
                                height: '16px'
                            }"
                        ></pt-icon>
                        <span>{{ currentChart.name }}</span>
                        <pt-icon
                            icon="pt-icon--arrow-down"
                            :icon-style="{
                                width: '10px',
                                height: '10px'
                            }"
                        ></pt-icon>
                    </div>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                            class="chart-dropdown-item"
                            v-for="item in charts"
                            :key="item.code"
                            :command="item.code"
                        >
                            <pt-icon
                                :icon="item.icon"
                                :icon-style="{
                                    width: '16px',
                                    height: '16px'
                                }"
                            ></pt-icon>
                            <span>{{ item.name }}</span>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>

            <div :class="$style.nodata" v-if="queryStatus === 'error'">
                <span v-html="$t('error.common_500')"></span>
            </div>
            <template v-else>
                <template v-if="hasChartData">
                    <div :class="$style.dataType" v-if="chart.chartSetting.visibleFilter">
                        <el-dropdown
                            trigger="click"
                            v-for="(filter, $filterIndex) in chart.chartSetting.filter"
                            :key="filter.code"
                            :class="$style.dataTypeDropdown"
                            @command="command => handleDataFilterChange(filter, $filterIndex, command)"
                        >
                            <div class="el-dropdown-link" :class="$style.dropdownLink">
                                <span>{{ getFilterName(filter) }}</span>
                                <pt-icon
                                    icon="pt-icon--arrow-down"
                                    :icon-style="{
                                        width: '10px',
                                        height: '10px'
                                    }"
                                ></pt-icon>
                            </div>
                            <el-dropdown-menu class="chart-dropdown-wrap" slot="dropdown">
                                <pt-scrollbar>
                                    <el-dropdown-item
                                        class="chart-dropdown-item"
                                        v-for="(item, $index) in filter.options"
                                        :key="$index"
                                        :command="item.code"
                                        :title="item.name"
                                    >
                                        <span>{{ item.name }}</span>
                                    </el-dropdown-item>
                                </pt-scrollbar>
                            </el-dropdown-menu>
                        </el-dropdown>

                        <el-dropdown
                            trigger="click"
                            v-if="chart.chartSetting.dataType.options"
                            :class="$style.dataTypeDropdown"
                            @command="command => handleDataTypeChange(command)"
                        >
                            <div class="el-dropdown-link" :class="$style.dropdownLink">
                                <span>{{ getDataTypeName() }}</span>
                                <pt-icon
                                    icon="pt-icon--arrow-down"
                                    :icon-style="{
                                        width: '10px',
                                        height: '10px'
                                    }"
                                ></pt-icon>
                            </div>
                            <el-dropdown-menu class="chart-dropdown-wrap" slot="dropdown">
                                <pt-scrollbar>
                                    <el-dropdown-item
                                        class="chart-dropdown-item"
                                        v-for="item in chart.chartSetting.dataType.options"
                                        :key="item.code"
                                        :command="item.code"
                                    >
                                        <span>{{ item.name }}</span>
                                    </el-dropdown-item>
                                </pt-scrollbar>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                    <div :class="$style.value" v-if="chart.chartSetting.visibleValue">
                        <b>{{ formatNumber(chart.chartSetting.value) }}</b>
                        {{ chart.chartSetting.dataType.unit }}
                    </div>
                    <div :class="$style.container" ref="chart">
                        <template v-if="chartData !== null">
                            <div :class="$style.number" v-if="chart.chartSetting.type === 'number'">
                                {{ chartData }}
                            </div>
                            <pt-table
                                v-else-if="chart.chartSetting.type === 'table'"
                                :column="chart.chartSetting.tableColumn"
                                :data="chartData"
                            ></pt-table>
                            <pt-highmaps
                                v-else-if="chart.chartSetting.type === 'map'"
                                :mapCode="chart.chartSetting.mapCode"
                                :series="chartData"
                                @change="handleMapChange"
                            ></pt-highmaps>
                            <pt-highcharts
                                v-else
                                :chart-type="currentChart.code"
                                :settings="chartSettings"
                                :series="chartData"
                            ></pt-highcharts>
                        </template>
                    </div>
                </template>
                <div :class="$style.nodata" v-else>{{ $t('users.no_data') }}</div>
            </template>
        </template>
    </div>
</template>

<script>
import { debounce } from 'throttle-debounce';
import axios from 'axios';
import usersApis from '@/apis/users.apis';
import numberUtils from '@/common/utils/number.utils';
import dateUtils from '@/common/utils/date.utils';
import commonUtils from '@/common/utils/common.utils';
import domUtils from '@/common/utils/dom.utils';
import colorConfig from '@/common/configs/color.config';

export default {
    name: 'Chart',

    props: {
        date: {
            type: Object,
            interface: {
                code: 'today',
                endTime: '2020/03/05',
                startTime: '2020/03/05'
            }
        },
        sid: String,
        chart: Object,
        filter: Object,
        timeZone: String
    },

    data() {
        const requestId = `FETCH_USERS_CHART_ID_${this.chart.code}`;
        const lineColors = [colorConfig['$pt-green-60'], 'rgba(0,169,46, .5)'];
        const chartSettings = {
            chart: {
                height: this.chart.chartSetting.height || 214
            },
            xAxis: {
                categories: []
            },
            colors: [
                colorConfig['$pt-green-60'],
                '#ffc82c',
                '#ff8900',
                '#9174e8',
                '#90f7fc',
                '#66cc33',
                '#fff650',
                '#5e78f7',
                '#fc95bd',
                '#996600'
            ]
        };
        const charts = [
            {
                code: 'line',
                name: '线图',
                icon: 'icon-chart-line'
            },
            {
                code: 'column',
                name: '柱图',
                icon: 'icon-chart-column'
            },
            {
                code: 'pie',
                name: '饼图',
                icon: 'icon-chart-pie'
            },
            {
                code: 'bar',
                name: '条形图',
                icon: 'icon-chart-bar'
            }
        ];
        const defaultChart = charts.find(item => item.default);
        const deviceMapper = {
            0: this.$t('common.others'),
            1: this.$t('common.smartphone'),
            2: this.$t('common.pc'),
            4: this.$t('common.tablet')
        };
        return {
            requestId,
            charts,
            chartData: null,
            chartDataTmp: null, //备份原始数据
            chartSettings,
            lineColors,
            queryDataExceededErrorCode: 'QUERY_DATA_EXCEEDED',
            currentChart: charts.find(item => item.code === this.chart.chartSetting.type) || defaultChart,
            deviceMapper,
            loading: true,
            loadingText: '',
            loadingTextStore: commonUtils.loadingTextStore(),
            queryStatus: 'start' //start/pending/success/error
        };
    },

    computed: {
        hasChartData() {
            if (this.queryStatus !== 'error') {
                const { type } = this.chart.chartSetting;
                let flag = false;
                switch (type) {
                    case 'number':
                        flag = this.chartData !== null;
                        break;
                    case 'table':
                    case 'map':
                        flag = this.chartData && this.chartData.length > 0;
                        break;
                    default:
                        flag =
                            this.chartData &&
                            this.chartData.length > 0 &&
                            this.chartData.some(list => {
                                return list && Array.isArray(list) ? list.length > 0 : (list.data || []).length > 0;
                            });
                        break;
                }
                return flag;
            }
            return false;
        },

        timeModel() {
            const { code, endTime, startTime, timeModel } = this.date;
            return code === 'custom' ? (endTime === startTime ? 'hour' : 'day') : timeModel;
        }
    },

    created() {
        this.$eventBus.$on('mainScroll', this.onScroll, false);
        window.addEventListener('resize', this.handleResize, false);
        this.$on('timeover', function(key) {
            console.log('timeover', key);
        });
    },

    mounted() {
        this.drawChart();
        this.queryDebounce = debounce(200, false, () => {
            this.redrawChart();
        });
    },

    beforeDestroy() {
        this.$eventBus.$off('mainScroll', this.onScroll, false);
        window.removeEventListener('resize', this.handleResize, false);
        this.loadingTextStore.stop();
        axios.cancel(this.requestId);
    },

    methods: {
        async fetchChartData(funName, filter) {
            this.loading = true;
            this.chartData = null;
            this.queryStatus = 'pending';
            this.loadingTextStore.start(() => {
                this.loadingText = this.$t('users.query_data_large_loading');
            });

            axios.cancel(this.requestId);
            const { startTime, endTime, timeType } = this.date;
            const [err, standardData] = await commonUtils.awaitWrap(
                usersApis.standardData(
                    null,
                    {
                        where: {
                            sid: this.sid,
                            funName: funName || this.chart.funName,
                            startTime,
                            endTime,
                            timeModel: this.timeModel,
                            timeType,
                            timeZone: this.timeZone,
                            audienceFilter: this.filter,
                            sequentialFlag: this.chart.sequentialFlag ? 1 : 0,
                            filter: filter || {}
                        }
                    },
                    { requestId: this.requestId }
                )
            );
            if (!axios.isCancel(standardData)) {
                const { currentResult, lastResult } = standardData;
                this.chartDataTmp = { currentResult, lastResult };
                this.setChartData(currentResult, lastResult);
                this.queryStatus = 'success';
            }else if (err || !standardData) {
                this.queryStatus = 'error';
                err?.code === this.queryDataExceededErrorCode && this.$emit('queryError');
            }
            this.loading = false;
            this.loadingTextStore.stop();
            this.$nextTick(() => {
                this.chart.chartSetting.type === 'number' &&
                    this.$refs.chart &&
                    this.setNumberFontSize(this.$refs.chart, this.chartData);
            });
        },

        handleChartTypeChange(code) {
            this.currentChart = this.charts.find(item => item.code === code);
        },

        handleDataTypeChange(command) {
            const { currentResult, lastResult } = this.chartDataTmp;
            const typeInfo = this.chart.chartSetting.dataType.options.find(item => item.code === command);
            const { code, unit } = typeInfo || {};
            this.$set(this.chart.chartSetting.dataType, 'value', code);
            this.$set(this.chart.chartSetting.dataType, 'unit', unit);
            this.setChartData(currentResult, lastResult);
        },

        handleDataFilterChange(filter, index, command) {
            this.$set(this.chart.chartSetting.filter[index], 'value', command);
            this.fetchChartData(null, {
                key: filter.postName,
                value: command
            });
        },

        handleMapChange(mapCode) {
            const funName = mapCode === 'world' ? 'countryReport' : 'regionReport';
            const filter = funName === 'regionReport' && {
                key: 'country',
                value: mapCode
            };
            this.chart.funName = funName;
            this.chart.chartSetting.mapCode = mapCode;
            this.fetchChartData(funName, filter);
        },

        getFilterName(filter) {
            const filterInfo = filter && filter.options.find(item => item.code === filter.value);
            return (filterInfo && filterInfo.name) || '';
        },

        getDataTypeName() {
            const { value, options } = this.chart.chartSetting.dataType;
            const dataTypeInfo = options && options.find(item => item.code === value);
            return (dataTypeInfo && dataTypeInfo.name) || '';
        },

        setNumberFontSize(chartDom, value) {
            let defaultWidth = 220;
            let defaultHeight = 100;
            let defaultFontSize = value || value === 0 ? Math.max(10 - String(value).length, 0) + 10 : 10;
            let currentWidth = chartDom.offsetWidth;
            let currentHeight = chartDom.offsetHeight;
            let sizePercent = Math.min(currentWidth / defaultWidth, currentHeight / defaultHeight);
            let currentFontSize = sizePercent * defaultFontSize;

            chartDom.style.fontSize = currentFontSize + 'px';
        },

        setChartData(data, lastTimeData) {
            const { type, dataType, visibleValue } = this.chart.chartSetting;
            switch (type) {
                case 'number':
                    const value = (data && data.find(item => item.key === this.chart.code)) || {
                        keyCount: 0,
                        userCount: 0
                    };
                    const { keyCount, userCount } = value;
                    this.chartData = userCount;
                    break;
                case 'table':
                    this.chartData = data || [];
                    break;
                case 'map':
                    this.chartData =
                        data &&
                        data.map(item => {
                            const { key, keyCount, userCount } = item;
                            return {
                                code: key,
                                region: 'Users',
                                value: item[dataType.value],
                                showValue: item[dataType.value]
                            };
                        });
                    break;
                default:
                    const dataList = data ? (Array.isArray(data) ? data : data.list || []) : [];
                    const lastTimeDataList = lastTimeData
                        ? Array.isArray(lastTimeData)
                            ? lastTimeData
                            : lastTimeData.list || []
                        : [];
                    const { startTime, endTime } = this.date;
                    const dateIsDay = startTime === endTime;
                    const categories = dateIsDay ? dateUtils.getHours() : dateUtils.getDays(startTime, endTime);
                    const chartData = this.chart.sequentialFlag ? [dataList, lastTimeDataList] : [dataList];
                    this.chartData = chartData.map((series, index) => {
                        if (this.chart.code === 'deviceReport') {
                            return {
                                name: this.chart.name,
                                data: series.map(item => {
                                    const { key, keyCount, userCount } = item;
                                    const name = this.deviceMapper[key + ''];
                                    const y = item[dataType.value] || userCount;
                                    return {
                                        name,
                                        y
                                    };
                                })
                            };
                        } else {
                            //环比时间是按时间差天数找到对应同等天数的上一周期
                            const isChainSeries = index !== 0;
                            const dateDiff = dateUtils.getDateDiff(startTime, endTime);
                            const seriesStartTime = isChainSeries
                                ? dateUtils.momentAdd(startTime, -dateDiff)
                                : startTime;
                            const seriesEndTime = isChainSeries ? dateUtils.momentAdd(startTime, -1) : endTime;
                            const seriesData = this.getDateValue(
                                seriesStartTime,
                                seriesEndTime,
                                series,
                                dataType.value || 'userCount'
                            );
                            return {
                                name: this.chart.name,
                                data: seriesData
                            };
                        }
                    });
                    this.chart.chartSetting.value = visibleValue && data ? data.count[dataType.value] || 0 : null;
                    this.chartSettings.xAxis.categories = categories;

                    if (type === 'line') {
                        this.chartSettings.colors = this.lineColors;
                    } else if (type === 'pie') {
                        delete this.chartSettings.xAxis.categories;
                    }
                    break;
            }
        },

        /**
         * 滚动事件回调函数
         */
        onScroll() {
            domUtils.domIsIn(this.$el) && this.queryStatus === 'start' && this.fetchChartData();
        },

        redrawChart() {
            // 切换时间或数据时，维持过滤选项
            let newFilter = null;
            const { funName, chartSetting } = this.chart;
            const { type, visibleFilter, mapCode, filter } = chartSetting || {};
            if (visibleFilter) {
                const { value, postName } = (filter || [{}])[0];
                newFilter = {
                    key: postName,
                    value
                };
            } else if (type === 'map') {
                newFilter = funName === 'regionReport' && {
                    key: 'country',
                    value: mapCode
                };
            }
            this.drawChart(newFilter);
        },

        drawChart(filter) {
            domUtils.domIsIn(this.$el)
                ? this.fetchChartData(null, filter)
                : (axios.cancel(this.requestId), (this.queryStatus = 'start'));
        },

        /**
         * 按时间区间获取连续时间值
         * @param {Sting}
         * @param {String}
         * @param {Array} value
         * @returns {Arra}
         */
        getDateValue(startTime, endTime, value, valueCode) {
            const isDay = startTime === endTime;
            const categories = isDay ? dateUtils.getHours() : dateUtils.getDays(startTime, endTime);
            return categories.map(categorie => {
                const categorieInfo = value.find(item => {
                    const { key } = item;
                    return (isDay ? `${key}:00` : key) === categorie;
                });
                const y = categorieInfo ? categorieInfo[valueCode] : 0;
                return {
                    name: isDay ? `${startTime} ${categorie}` : categorie,
                    y
                };
            });
        },

        formatNumber(num) {
            return num !== undefined && numberUtils.formatNumber(num);
        },

        handleResize() {
            this.chart.chartSetting.type === 'number' &&
                this.$refs.chart &&
                this.setNumberFontSize(this.$refs.chart, this.chartData);
        }
    },

    watch: {
        date: {
            handler: function() {
                this.$route.name === 'UsersSettingReport' && this.queryDebounce();
            },
            deep: true
        },

        filter: {
            handler: function(newVal, oldVal) {
                this.$route.name === 'UsersSettingReport' && this.queryDebounce();
            },
            deep: true
        },

        $route(val) {
            const { name } = val;
            name === 'UsersSettingReport' && this.queryDebounce();
        }
    }
};
</script>

<style lang="scss" module>
@import '@/styles/import.scss';

$chartMargin: 20px;

:global {
    .chart-dropdown-item {
        display: flex;
        align-items: center;

        &:hover {
            span {
                color: $pt-green-70;
            }
            svg {
                fill: $pt-green-70;
            }
        }

        span {
            margin: 0 8px 0 4px;
            font-size: 14px;
            color: $pt-black-600;
        }

        svg {
            fill: $pt-black-600;
        }
    }

    .chart-dropdown-wrap {
        max-height: 200px;
        max-width: 200px;

        .chart-dropdown-item span {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }
}

.chart {
    height: 346px;
    padding: 20px 0;
    border: 1px solid $pt-black-40;
    border-radius: 4px;
    background-color: $pt-white;
    display: flex;
    flex-direction: column;

    .header {
        position: relative;
        flex: 0 0 24px;
        overflow: hidden;
        display: flex;
        justify-content: flex-end;
        padding: 0 $chartMargin;

        .title {
            font-size: 16px;
            color: #091e42;
            font-weight: 500;
            white-space: nowrap;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .dropdownLink {
        display: flex;
        align-items: center;
        outline: none;
        cursor: pointer;

        &:hover {
            span {
                color: $pt-green-70;
            }
            svg {
                fill: $pt-green-70;
            }
        }

        span {
            margin: 0 8px 0 4px;
            font-size: 14px;
            color: $pt-green-60;
        }

        svg {
            fill: $pt-green-60;
        }
    }

    .dropdownItem {
        display: flex;
        align-items: center;

        &:hover {
            span {
                color: $pt-green-70;
            }
            svg {
                fill: $pt-green-70;
            }
        }

        span {
            margin: 0 8px 0 4px;
            font-size: 14px;
            color: $pt-green-60;
        }
    }

    .dataType {
        margin-top: 8px;
        display: flex;
        align-items: center;
        justify-content: center;

        .dataTypeDropdown:not(:last-child) {
            margin-right: 30px;
        }
    }

    .value {
        margin-top: 20px;
        color: $pt-black-600;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;

        b {
            font-size: 20px;
            font-weight: 500;
            margin-right: 6px;
        }
    }

    .container {
        margin: 20px 36px 0;
        flex: 1;
        overflow: hidden;
    }

    .number {
        color: $pt-black-600;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .nodata {
        flex: 1;
        justify-content: center;
        align-items: center;
        display: flex;
        color: $pt-black-60;
        padding: 0 10px;
    }
}
</style>
