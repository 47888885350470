"use strict";
/**
 * 全站配色方案
 * 与 【@/assets/style/framework/_colors.scss】文件中保持一致
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    '$pt-white': '#fff',
    // Green
    '$pt-green-5': '#e6f7e7',
    '$pt-green-10': '#c3eac4',
    '$pt-green-20': '#9cdc9f',
    '$pt-green-30': '#70cf77',
    '$pt-green-40': '#4ac458',
    '$pt-green-50': '#16b836',
    '$pt-green-60': '#00A92E',
    '$pt-green-70': '#009721',
    '$pt-green-80': '#008613',
    '$pt-green-90': '#006700',
    '$pt-green-100': '#001d6c',
    '$pt-green-200': '#001141',
    '$pt-green-300': '#001E00',
    // Black
    '$pt-black': '#000',
    '$pt-black-10': '#FAFBFC',
    '$pt-black-20': '#F4F5F7',
    '$pt-black-30': '#EBECF0',
    '$pt-black-40': '#DFE1E6',
    '$pt-black-50': '#C1C7D0',
    '$pt-black-60': '#B3BAC5',
    '$pt-black-70': '#A5ADBA',
    '$pt-black-80': '#97A0AF',
    '$pt-black-90': '#8993A4',
    '$pt-black-100': '#7A869A',
    '$pt-black-200': '#6B778C',
    '$pt-black-300': '#5E6C84',
    '$pt-black-400': '#505F79',
    '$pt-black-500': '#42526E',
    '$pt-black-600': '#344563',
    '$pt-black-700': '#253858',
    '$pt-black-800': '#172B4D',
    '$pt-black-900': '#091E42',
    // Theme color default definitions
    '$pt-info': '#29B6F6',
    '$pt-info-hover': '#E9F7FE',
    '$pt-success': '#14A800',
    '$pt-success-hover': '#E7F6E5',
    '$pt-renubd': '#FFC82C',
    '$pt-renubd-hover': '#FFF7E1',
    '$pt-warning': '#FF6F00',
    '$pt-warning-hover': '#FFF4EA',
    '$pt-danger': '#F54336',
    '$pt-danger-hover': '#FFEBEE',
    versionColor: [
        '#58c70f',
        '#BF826E',
        '#7FE7D6',
        '#FF7558',
        '#6D7A04',
        '#E2B47C',
        '#804774',
        '#F2CA52',
        '#775F47',
        '#E95D79'
    ]
};
