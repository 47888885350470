<template>
    <div :class="$style.report">
        <user-group-tips v-if="hasExceededData"></user-group-tips>
        <template v-else>
            <div class="x-wrap x-wrap--full" :class="$style.tools">
                <time-selector :timeZone="profileInfo.timeZone" @change="handleDateChange"></time-selector>
            </div>
            <div class="x-wrap x-wrap--full" :class="$style.containerWrap">
                <div :class="$style.container" v-if="date">
                    <div v-for="chart in charts" :key="chart.code" :class="$style.chart" :data-span="chart.span">
                        <chart
                            :date="date"
                            :sid="profileInfo.sid"
                            :timeZone="profileInfo.timeZone"
                            :chart="chart"
                            :filter="usersFilter"
                            @queryError="hasExceededData = true"
                        ></chart>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import dayjs from 'dayjs';
import usersApis from '@/apis/users.apis';
import commonUtils from '@/common/utils/common.utils';
import cloneUtils from '@/common/utils/clone.utils';

import Chart from './Chart';
import reportConfig from '../usergrp-report.config';
import TimeSelector from '@/common/packages/time-selector/TimeSelector';

import dateUtils from '@/common/utils/date.utils';
import UserGroupTips from './UserGroupTips';
const FORMAT = 'yyyy/mm/dd';

export default {
    name: 'UsersSettingReport',

    data() {
        return {
            hasExceededData: false,
            hasExceededDataErrorCode: 'QUERY_DATA_EXCEEDED',
            charts: cloneUtils.deep(reportConfig),
            date: null
        };
    },

    computed: {
        ...mapState('main', ['profileInfo']),
        ...mapState('users', ['userGroupInfo']),
        ...mapGetters('users', ['usersFilter'])
    },

    async created() {
        const eventCurveChart = this.charts.find(chart => chart.code === 'eventCurve');
        const [err, dictData] = await commonUtils.awaitWrap(this.fetchEventName());
        if (err || !dictData) {
            console.log('featch User Property Name failed.');
        } else {
            const eventName = dictData.map(item => {
                return { code: item.key, name: item.key };
            });
            if (eventCurveChart) {
                const events = eventCurveChart.chartSetting.filter.find(item => item.postName === 'events');
                events && events.options.push(...(eventName || []));
            }
        }
    },

    methods: {
        handleDateChange(val) {
            this.date = val;
            console.log('date', val);
        },

        fetchEventName() {
            const { sid, timeZone } = this.profileInfo;
            return usersApis.getDictData(null, {
                where: {
                    sid,
                    funName: 'eventName',
                    timeZone,
                    limit: 100
                }
            });
        }
    },

    watch: {
        date: {
            handler: function() {
                this.hasExceededData = false;
            },
            deep: true
        },

        usersFilter: {
            handler: function(newVal, oldVal) {
                this.hasExceededData = false;
            },
            deep: true
        }
    },

    components: {
        Chart,
        TimeSelector,
        UserGroupTips
    }
};
</script>

<style lang="scss" module>
@import '@/styles/import.scss';

.report {
    padding-bottom: $page-content-padding-bottom;

    .tools {
        margin-bottom: 10px;
        display: flex;
        justify-content: flex-end;

        a {
            font-size: 14px;
            font-weight: 600;
            display: flex;
            align-items: center;
            @include link($pt-green-60, $pt-green-70);
            text-decoration: none;
        }
    }

    .containerWrap {
        position: relative;
        flex: 1;
        overflow: hidden;
    }

    .container {
        position: relative;
        display: grid;
        justify-content: space-between;
        grid-gap: 20px 20px;
        grid-template-columns: repeat(24, 1fr);

        .chart {
            &[data-span='16'] {
                grid-column-start: span 16;
            }
            &[data-span='12'] {
                grid-column-start: span 12;
            }
            &[data-span='8'] {
                grid-column-start: span 8;
            }
            &[data-span='6'] {
                grid-column-start: span 6;
            }
        }
    }

    .row {
        margin-bottom: 20px;
    }
}
</style>
