/**
 * timeModel: ['hour', 'day'], 数据最小颗粒度
 * timeType: ['day', 'week', 'month'], 时间周期单位
 */
export default [
    {
        i18nCode: 'time_range.today',
        code: 'today',
        timeModel: 'hour',
        timeType: 'day'
    },
    {
        i18nCode: 'time_range.yesterday',
        code: 'yesterday',
        timeModel: 'hour',
        timeType: 'day'
    },
    {
        i18nCode: 'time_range.this_week',
        code: 'this_week',
        timeModel: 'day',
        timeType: 'week'
    },
    {
        i18nCode: 'time_range.last_week',
        code: 'last_week',
        timeModel: 'day',
        timeType: 'week'
    },
    {
        i18nCode: 'time_range.this_month',
        code: 'this_month',
        timeModel: 'day',
        timeType: 'month'
    },
    {
        i18nCode: 'time_range.last_month',
        code: 'last_month',
        timeModel: 'day',
        timeType: 'month'
    },
    {
        configs: { min: 1, max: 999, isIncludeToday: true },
        i18nCode: 'time_range.last_days',
        code: 'past',
        timeModel: 'day',
        timeType: 'day',
        default: true,
        type: 'number'
    },
    {
        i18nCode: 'time_range.custom',
        code: 'custom',
        timeModel: 'day',
        timeType: 'day',
        type: 'range'
    }
];
